import React, { useState, useEffect } from "react";
import ProjectItem from "./ProjectItem";

export default function Projects() {
  const [projects, setProjects] = useState([]);
  const fetchData = async () => {
    const url = "projectsData.json";
    const data = await fetch(url);
    let parseData = await data.json();
    setProjects(parseData.projects);
  };
  
  const stylesheet = {
    PointerEvent: "none",
    color: "grey",
    cursor: "not-allowed"
  }

  useEffect(() => {fetchData()}, []);

  return (
    <>
      <div
        className="mainContainer container px-3 pt-5 py-3 text-white "
        id="projects"
      >
        <h2 className="text-center w-100">What I Done</h2>
        <span
          className="d-block text-center mb-5"
          style={{
            width: "120px",
            height: "1px",
            margin: "0 auto",
            backgroundColor: "#0dcbe0",
          }}
        ></span>

        <div className="row">
          <div className="d-flex justify-content-evenly flex-wrap">
            {projects.slice(0).reverse().map((elem) => {
              return (
                <ProjectItem to={`${elem.url}?q=${elem.key}`} urlToImage={elem.urlToImage} key={elem.key} />
              );
            })}
          </div>
        </div>

        <div className="row">
          <a href={projects.length < 3 ? null : "/projects"} className="text-center mt-5" style={projects.length < 3 ? stylesheet : {color: "blueviolet"}}>
            See All Projects
          </a>
        </div>
      </div>
    </>
  );
}

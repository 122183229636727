import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const DisplayProject = () => {
  const [projects, setProjects] = useState([]);
  const fetchData = async () => {
    const url = "projectsData.json";
    const data = await fetch(url);
    let parseData = await data.json();
    setProjects(parseData.projects);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Search Parameter Queries
  const [searchParams, setSearchParams] = useSearchParams();
  const query = searchParams.get("q");

  // Stylesheets
  const content = {
    backgroundColor: "#c9c7c722",
    color: "#ffffff",
  };
  const contentImg = {
    width: "100%",
  };

  return projects.map((elem) => {
    if (elem.key === query) {
      return (
        <div
          className="mainContainer d-flex align-items-center"
          style={{ minHeight: "100vh" }}
          key={elem.key}
        >
          <div className="container py-3" id="content" style={content}>
            <div className="row">
              <div key={elem.key}>
                <div className="img">
                  <img
                    src={elem.urlToImage}
                    className="d-inline"
                    alt="#"
                    style={contentImg}
                  />
                </div>
                <div className="">
                  <h4>
                    {elem.title}
                    <a
                      href={elem.viewSite}
                      className="btn btn-sm btn-primary mx-2"
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Site
                    </a>
                  </h4>
                  <span className="d-block">{elem.content}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  });
};

export default DisplayProject;

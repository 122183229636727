import React from "react";

function Services() {
  return (
    <>
      <div className="mainContainer container px-3 pt-5 py-3 " id="services">
        <h2 className="text-center text-white w-100">What I Offer</h2>
        <span
          className="d-block text-center mb-5"
          style={{
            width: "120px",
            height: "1px",
            margin: "0 auto",
            backgroundColor: "#0dcbe0",
          }}
        ></span>
        <div className="row">
          <div className=" d-flex justify-content-evenly flex-wrap">
            {/* <!-- Card --> */}
            <div className="card shadow-lg bg-dark col-lg-3 col-md-12 col-sm-12 text-center pt-4 m-2">
              {/* <!-- <img src="images/profile.jpeg" className="card-img-top" alt="..."> --> */}
              <i className="fa-solid fa-mobile fa-4x"></i>
              <div className="card-body">
                <h5 className="card-title">Fully Responsive Design</h5>
                <p className="card-text">
                  I develop the fully mobile friendly design that can be run
                  easily on every device like mobile, laptop and PC
                </p>
              </div>
            </div>
            {/* <!-- Card Ends --> */}

            {/* <!-- Card --> */}
            <div className="card shadow-lg bg-dark col-lg-3 col-md-12 col-sm-12 text-center pt-4 m-2">
              <i className="fa-brands fa-searchengin fa-4x"></i>
              <div className="card-body">
                <h5 className="card-title">SEO Optimized</h5>
                <p className="card-text">
                  Your website will be optimized on the standard of famous
                  search engines
                </p>
              </div>
            </div>
            {/* <!-- Card Ends --> */}

            {/* <!-- Card --> */}
            <div className="card shadow-lg bg-dark col-lg-3 col-md-12 col-sm-12 text-center pt-4 m-2">
              <i className="fa-solid fa-window-restore fa-4x"></i>
              <div className="card-body">
                <h5 className="card-title">Cross-Browser Compatible</h5>
                <p className="card-text">
                  Website that will work easily and smoothly on every browser
                </p>
              </div>
            </div>
            {/* <!-- Card Ends --> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;

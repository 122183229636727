import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function ProjectItem(props) {
  return (
    <Link
      to={props.to}
      className="card bg-dark text-white col-lg-3 col-md-12 col-sm-12 text-center m-2"
    >
      <img src={props.urlToImage} className="card-img" alt="Project" />
      <div className="card-img-overlay d-flex align-items-center justify-content-center">
        <h5 className="card-title">{props.title}</h5>
      </div>
    </Link>
  );
}

PropTypes.defaultProps = {
  title: "title here",
  description: "description here",
  projectURL: "/displayProject",
};

import React from "react";

function About() {
  return (
    <div
      className="mainContainer container px-3 pt-5 py-3 text-white "
      id="about"
    >
      <h2 className="text-center w-100">Who am I</h2>
      <span
        className="d-block text-center mb-5"
        style={{
          width: "120px",
          height: "1px",
          margin: "0 auto",
          backgroundColor: "#0dcbe0",
        }}
      ></span>
      <div className="row" id="aboutContent">
        <div className="img col-lg-5 col-md-12">
          <img
            src="images/profile.jpg"
            className="d-inline border border-4 border-white rounded"
            alt="images"
          />
        </div>
        <div className="col-lg-7 col-md-12 px-lg-5">
          <span className="d-block">
            Hello there! I'm thrilled to have you visit my website. I'm a passionate and experienced MERN Stack Developer with a strong commitment to delivering high-quality web solutions. My journey in the world of programming began several years ago, and since then, I've honed my skills to become proficient in the MERN (MongoDB, Express.js, React.js, Node.js) stack. With a knack for problem-solving and an eye for detail, I'm dedicated to creating innovative and efficient web applications that meet and exceed client expectations.<br /><br />
          </span>
          <span className="d-block">
            <h5>Technical Expertise:</h5>
            ✓ MongoDB: I have extensive experience working with MongoDB and designing efficient database structures to ensure optimal performance and scalability.<br />
            ✓ Express.js: I'm proficient in utilizing Express.js to build robust and secure back-end APIs that seamlessly integrate with the front-end.<br />
            ✓ React.js: With a deep understanding of React.js, I specialize in creating interactive and user-friendly interfaces, ensuring a smooth and intuitive user experience.<br />
            ✓ Node.js: I'm well-versed in leveraging Node.js to develop scalable server-side applications, handling multiple concurrent requests efficiently.<br /><br />
          </span>

          <span className="d-block">
          <h5>Services I Provide:</h5>
          ✓ Full-Stack Web Development: I offer end-to-end web development services, from conceptualization and design to development and deployment. I ensure that your web application is built with the latest industry standards and tailored to your specific requirements.<br />
          ✓ API Development: I excel at crafting robust and scalable APIs using the MERN stack, enabling seamless communication between your front-end and back-end systems.<br />
          ✓ Database Design and Optimization: I can help you design and optimize your MongoDB database, ensuring efficient data management and high-performance query execution.<br />
          ✓ Third-Party Integrations: Whether it's integrating payment gateways, social media APIs, or other third-party services, I have the expertise to streamline your application's functionality and enhance user experience.<br />
          </span>
        </div>
      </div>
    </div>
  );
}

export default About;

import React, { Fragment }from 'react'
import {BrowserRouter as Router, 
  Routes, 
  Route
} from 'react-router-dom'
import Navbar from './components/Navbar'
import Main from './components/Main'
import About from './components/About';
import Services from './components/Services';
import Projects from './components/Projects';
import Contact from './components/Contact';
import ProjectDisplay from './components/ProjectDisplay'

export function App(){
    return(
      // Whatever you write here will be implemented in whole application
      <Router>
        <Fragment >
          <Navbar title="Ayaz Soomro" link1="Home" link2="About" link3="Services" link4="Projects" link5="Contact"/>
          <Routes>
            <Route exact path='/' element={<Main />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/services' element={<Services />} />
            <Route exact path='/projects' element={<Projects />} />
            <Route exact path='/contact' element={<Contact />} />
            <Route exact path='/projectDisplay' element={<ProjectDisplay />} />
          </Routes>
        </Fragment>
      </Router>
    );
}
export default App;

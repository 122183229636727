import React from "react";
import PropTypes from "prop-types";
import { 
  NavLink
} from 'react-router-dom';

export default function Navbar(props) {

    return (
      <>
      {/* <!-- Sidebar --> */}
    <div
    className="d-flex flex-column mx-3 ms-0 mt-0 pt-3 text-white bg-dark"
    id="sidebar"
  >
    {/* <!-- Profile --> */}
    <div className="profile my-4">
      <img src="images/profile.jpg" width="130" className="img-thumbnail rounded-circle d-block mx-auto" alt="profile" />
      <h4 className="text-center d-block my-2">{props.title}</h4>
    </div>
    {/* <!-- Profile Ends --> */}
    <ul className="nav flex-column mb-0 mt-3" id="sidebarUl">
      <li className="nav-item">
        <NavLink to="/" className="nav-link text-white py-1">
          <i className="fa-solid fa-house fa-lg me-1"></i>
          {props.link1}
        </NavLink>
      </li>

      <hr className="mx-3" />
      <li>
        <NavLink to="/about" className="nav-link text-white py-1">
          <i className="fa-solid fa-user fa-lg me-1"></i>
          {props.link2}
        </NavLink>
      </li>

      <hr className="mx-3" />
      <li>
        <NavLink to="/services" className="nav-link text-white py-1">
          <i className="fa-solid fa-globe fa-lg me-1"></i>
          {props.link3}
        </NavLink>
      </li>

      <hr className="mx-3" />
      <li>
        <NavLink to="/projects" className="nav-link text-white py-1">
          <i className="fa-solid fa-code fa-lg me-1"></i>
          {props.link4}
        </NavLink>
      </li>

      <hr className="mx-3" />
      <li>
        <NavLink to="/contact" className="nav-link text-white py-1">
          <i className="fa-solid fa-comments fa-lg me-1"></i>
          {props.link5}
        </NavLink>
      </li>
    </ul>
  </div>

  {/* <!-- Dropdown Menubar --> */}
  <nav
    className="d-md-block d-lg-none navbar navbar-expand-lg navbar-dark text-white bg-dark"
    id="dropdown"
  >
    <div className="container-fluid bg-dark">
      <ul className="navbar-nav bg-dark">
        {/* <!-- Dropdown --> */}
        <li className="nav-item dropdown">
          <NavLink
            className="nav-link dropdown-toggle"
            to="/"
            id="navbarDropdownMenuLink"
            role="button"
            data-mdb-toggle="dropdown"
            aria-expanded="false"
          >
            {props.title}
          </NavLink>
          <ul
            className="dropdown-menu bg-dark text-white"
            style={{minWidth: "320px"}}
            aria-labelledby="navbarDropdownMenuLink"
          >
            <li>
              <NavLink className="dropdown-item text-white " to="/">
                <i className="fa-solid fa-house me-1"></i>
                {props.link1}</NavLink>
            </li>

            <li>
              <NavLink to="/about" className="dropdown-item text-white">
                <i className="fa-solid fa-user me-1"></i>
                {props.link2}
              </NavLink>
            </li>

            <li>
              <NavLink to="/services" className="dropdown-item text-white">
                <i className="fa-solid fa-globe me-1"></i>
                {props.link3}
              </NavLink>
            </li>

            <li>
              <NavLink to="/projects" className="dropdown-item text-white">
                <i className="fa-solid fa-code me-1"></i>
                {props.link4}
              </NavLink>
            </li>

            <li>
              <NavLink to="/contact" className="dropdown-item text-white">
                <i className="fa-solid fa-comments me-1"></i>
                {props.link5}
              </NavLink>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>
  {/* <!-- Sidebar Ends --> */}
  </>
    );
}

// These are used for function based components
Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  about: PropTypes.string.isRequired
};

// defaultProps will be called autometically when no props are called
Navbar.defaultProps = {
    title: "Title Here",
    about: "About Link Here"
};
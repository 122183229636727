import React from "react";
import About from './About'
import Contact from "./Contact";
import Projects from "./Projects";
import Services from "./Services";

export default function Main() {
  return (
    <>
    <div className="mainContainer d-flex flex-column mx-0">
      {/* <!-- Banner --> */}
      <div
        className="banner d-flex align-items-center justify-content-center flex-column text-white"
        id="home"
      >
        <h2>Hey! This is me Ayaz Soomro</h2>
        <h4>A Full-Stack Website Developer</h4>
        <a
          href="https://www.fiverr.com/theayazsoomro/develop-your-modern-and-responsive-website-in-react-js"
          id="btnHire"
          className="px-4 mt-1"
          target="_blank"
          rel="noreferrer"
        >
          Hire Me
        </a>
        <img src="images/banner.jpg" className="bannerImg" alt="bannerImg" />
      </div>
    </div>
    <About />
    <Services />
    <Projects />
    <Contact />
    </>
  );
}

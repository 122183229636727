import React from 'react'

function Contact() {
  return (
    <>
      <div className="mainContainer container px-3 pt-5 py-3 text-white" id="contact">
    <h2 className="text-center w-100">Contact Me</h2>
    <span className="d-block text-center mb-5" style={{
          width: "120px",
          height: "1px",
          margin: "0 auto",
          backgroundColor: "#0dcbe0",
        }}></span>
    <div className="row">
      <div className="col-md-6 mr-auto ">
      {/* <!-- <h2>Stay Connected</h2> --> */}
      <p className="mb-3 mt-2">If you have any question or query to ask regarding your website you may ask freely, I will be happy to answer your valuable questions.</p>
      <ul className="list-unstyled mb-4">
          <li className="d-flex text-black">
          <span className="mr-3"><span className="fa fa-location-dot me-2"></span></span> Jacobabad, Sindh, Pakistan
          </li>
          <li className="d-flex text-black mt-3">
          <span className="mr-3 "><span className="fa fa-location me-2"></span></span> Social Addresses
          </li>
          <li className="d-flex text-black mt-2">
          <a href='https://www.facebook.com/theayazsoomro' target='_blank' rel="noreferrer" style={{color: "#fff"}}><span className="fa-brands fa-facebook fa-lg"></span></a>
          <a href='https://www.instagram.com/theayazsoomro' target='_blank' rel="noreferrer" style={{color: "#fff"}}><span className="fa-brands fa-instagram ms-4 fa-lg"></span></a>
          <a href='https://www.linkedin.com/in/theayazsoomro' target='_blank' rel="noreferrer" style={{color: "#fff"}}><span className="fa-brands fa-linkedin ms-4 fa-lg"></span></a>
          <a href='https://www.youtube.com/@theayazsoomro' target='_blank' rel="noreferrer" style={{color: "#fff"}}><span className="fa-brands fa-youtube ms-4 fa-lg"></span></a>
          <a href='https://www.github.com/theayazsoomro' target='_blank' rel="noreferrer" style={{color: "#fff"}}><span className="fa-brands fa-github ms-4 fa-lg"></span></a>
          <a href='https://stackoverflow.com/users/14247985/ayaz-soomro' target='_blank' rel="noreferrer" style={{color: "#fff"}}><span className="fa-brands fa-stack-overflow ms-4 fa-lg"></span></a>
          </li>
      </ul>
      
      </div>
      <div className="col-md-6">
        {/* <!-- Map --> */}
      <iframe title='Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d71733.93341879269!2d68.42831088950355!3d28.2822312236354!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x393473e0c6e18efb%3A0xa870b5e0b1bbf8cb!2sJacobabad%2C%20Sindh%2C%20Pakistan!5e1!3m2!1sen!2s!4v1672424600128!5m2!1sen!2s" width="100%" height="360" style={{border: "0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
      {/* <!-- Map Ends --> */}
      </div>
    </div>
 </div>
    </>
  )
}

export default Contact